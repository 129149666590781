<template>
  <div style="padding-top: 70px">
    <section id="text-block">
      <div class="container" v-if="countryCodeName === 'es'">
        <h1>Lista de socios - instituciones financieras, intermediarios de crédito</h1>
        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Entidad legal</th>
                <th>Sitio web</th>
                <th>Email, número de teléfono</th>
                <th>Política de privacidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Access Finance, SL</td>
                <td><a href="https://axi-card.es/" rel="nofollow noopener" target="_blank">https://axi-card.es/</a></td>
                <td><a href="mailto:help@axi-card.es">help@axi-card.es</a><br />+34 910601600 / +34 900599281</td>
                <td><a href="https://axi-card.es/rgpd" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>Arena Finance Spain, SL</td>
                <td>
                  <a href="https://smartcredito.es/" rel="nofollow noopener" target="_blank">https://smartcredito.es/</a>
                </td>
                <td><a href="mailto:info@smartcredito.es">info@smartcredito.es</a><br />+34 919 467 100</td>
                <td>
                  <a
                    href="https://smartcredito.es/wp-content/uploads/2024/12/POLITICA-DE-PRIVACIDAD-16.12.24.pdf"
                    rel="nofollow noopener"
                    target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>BLUE FINANCE IBÉRICA, SLU</td>
                <td><a href="https://www.azlo.es/" rel="nofollow noopener" target="_blank">https://www.azlo.es/</a></td>
                <td><a href="mailto:hola@azlo.es">hola@azlo.es</a><br />+34 917 373 336</td>
                <td>
                  <a href="https://www.azlo.es/legal/politica-privacidad/" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td>Carback Solutions, SL</td>
                <td><a href="https://carback.es/" rel="nofollow noopener" target="_blank">https://carback.es/</a></td>
                <td>+34 900 900 797</td>
                <td><a href="https://carback.es/aviso-legal/" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>CAR SERVICE PARTNER SPAIN, SLU</td>
                <td>
                  <a href="https://www.cash4car.es/" rel="nofollow noopener" target="_blank">https://www.cash4car.es/</a>
                </td>
                <td><a href="mailto:info@cash4car.es">info@cash4car.es</a><br />+34 900 670 040</td>
                <td>
                  <a
                    href="https://www.cash4car.es/proteccion-de-datos-de-caracter-personal-segun-el-rgpd/"
                    rel="nofollow noopener"
                    target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>Credirect Préstamos, SLU</td>
                <td><a href="https://creditero.es/" rel="nofollow noopener" target="_blank">https://creditero.es/</a></td>
                <td><a href="mailto:hola@creditero.es">hola@creditero.es</a><br />+34 911 095 790</td>
                <td>
                  <a href="https://creditero.es/condiciones/politica-de-privacidad" rel="nofollow noopener" target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>Clidrive Capital, SL</td>
                <td>
                  <a href="https://www.clidrive.com/" rel="nofollow noopener" target="_blank">https://www.clidrive.com/</a>
                </td>
                <td><a href="mailto:contacto@clidrive.com">contacto@clidrive.com</a><br />+34 919 49 62 37</td>
                <td>
                  <a href="https://www.clidrive.com/politica-de-privacidad" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td>Fincloudapps, SL</td>
                <td><a href="https://dineti.es/" rel="nofollow noopener" target="_blank">https://dineti.es/</a></td>
                <td><a href="mailto:info@dineti.es">info@dineti.es</a></td>
                <td>
                  <a
                    href="https://dineti.es/politica-de-proteccion-de-datos/?_gl=1*mc6vz*_up*MQ..*_ga*MTUwNDQzMDUyNS4xNzQxOTQyMTg4*_ga_XMN33N5WDQ*MTc0MTk0MjE4Ny4xLjEuMTc0MTk0MjE4Ny4wLjAuMA.."
                    rel="nofollow noopener"
                    target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>NEWTON FINTECH INVESTMENT SPAIN, SL</td>
                <td>
                  <a href="https://www.oneprestamos.com/" rel="nofollow noopener" target="_blank"
                    >https://www.oneprestamos.com/</a
                  >
                </td>
                <td><a href="mailto:service@oneprestamos.com">service@oneprestamos.com</a><br />+34 900 808 635</td>
                <td>
                  <a
                    href="https://www.oneprestamos.com/static/web/privacy-policy.html"
                    rel="nofollow noopener"
                    target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>GF MONEY CONSUMER FINANCE SPAIN, SL</td>
                <td><a href="https://dinevo.es/" rel="nofollow noopener" target="_blank">https://dinevo.es/</a></td>
                <td><a href="mailto:info@dinevo.es">info@dinevo.es</a><br />+34 910 60 60 61</td>
                <td>
                  <a href="https://dinevo.es/politica-de-privacidad/" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td>IBANCAR WORLD, SL</td>
                <td><a href="https://ibancar.com/" rel="nofollow noopener" target="_blank">https://ibancar.com/</a></td>
                <td><a href="mailto:info@ibancar.com">info@ibancar.com</a><br />+34 952 864 645</td>
                <td><a href="https://ibancar.com/politica-privacidad" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>IBERCRÉDITO ONLINE, SL</td>
                <td>
                  <a href="https://www.ibercredito.es/" rel="nofollow noopener" target="_blank"
                    >https://www.ibercredito.es/</a
                  >
                </td>
                <td><a href="mailto:clientes@ibercredito.es">clientes@ibercredito.es</a><br />+34 902 099 839</td>
                <td>
                  <a href="https://www.ibercredito.es/politica-de-privacidad/" rel="nofollow noopener" target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>ID Finance Spain, SL</td>
                <td>
                  <a href="https://www.moneyman.es/" rel="nofollow noopener" target="_blank">https://www.moneyman.es/</a>
                </td>
                <td><a href="mailto:clientes@moneyman.es">clientes@moneyman.es</a><br />+34 937 227 354 / +34 900 533 827</td>
                <td>
                  <a href="https://www.moneyman.es/politica-de-privacidad/" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td>KVIKU Global LTD</td>
                <td><a href="https://kviku.es/" rel="nofollow noopener" target="_blank">https://kviku.es/</a></td>
                <td><a href="mailto:support@kviku.es">support@kviku.es</a><br />+34 851 817 056</td>
                <td><a href="https://kviku.es/files/privacy_sl.pdf" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>Lea Bank, AB</td>
                <td><a href="https://leabank.es/" rel="nofollow noopener" target="_blank">https://leabank.es/</a></td>
                <td>+34 902028330</td>
                <td>
                  <a href="https://leabank.es/sobre-nosotros/politica-privacidad" rel="nofollow noopener" target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>Loaney Finance, SL</td>
                <td><a href="https://loaney.es/" rel="nofollow noopener" target="_blank">https://loaney.es/</a></td>
                <td><a href="mailto:hola@Loaney.es">hola@Loaney.es</a></td>
                <td><a href="https://loaney.es/company/privacy" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>MANA CONCEPT, SL</td>
                <td><a href="https://finjet.es/" rel="nofollow noopener" target="_blank">https://finjet.es/</a></td>
                <td><a href="mailto:info@finjet.es">info@finjet.es</a><br />+34 900 808 634</td>
                <td>
                  <a href="https://finjet.es/politica-de-privacidad/" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td>NBQ Technology, SAU</td>
                <td>
                  <a href="https://www.quebueno.es/" rel="nofollow noopener" target="_blank">https://www.quebueno.es/</a>
                </td>
                <td><a href="mailto:info@quebueno.es">info@quebueno.es</a><br />+34 932 71 44 88/ +34 900 83 11 63</td>
                <td><a href="https://www.quebueno.es/" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>Novum Bank Ltd</td>
                <td><a href="https://www.cashper.es/" rel="nofollow noopener" target="_blank">https://www.cashper.es/</a></td>
                <td><a href="mailto:clientes@cashper.es">clientes@cashper.es</a><br />+34 91 005 20 04/+34 900 525 278</td>
                <td>
                  <a href="https://www.cashper.es/rights/privacy-statement" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td>RatingHub, SL</td>
                <td>
                  <a href="https://www.credito-claro.es/" rel="nofollow noopener" target="_blank"
                    >https://www.credito-claro.es/</a
                  >
                </td>
                <td><a href="mailto:contacto@credito-claro.es">contacto@credito-claro.es</a></td>
                <td>
                  <a href="https://docs.credito-claro.es/legal/ES/PrivacyPolicy.pdf" rel="nofollow noopener" target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>Reparadora RTD España, SL</td>
                <td>
                  <a href="https://www.resuelveinfo.es/" rel="nofollow noopener" target="_blank"
                    >https://www.resuelveinfo.es/</a
                  >
                </td>
                <td>+34 910 053 415</td>
                <td>
                  <a href="https://bravocredito.es/politica-de-clientes-y-prospectos" rel="nofollow noopener" target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
              <tr>
                <td>Rouvac OÜ</td>
                <td><a href="https://creditoflip.es/" rel="nofollow noopener" target="_blank">https://creditoflip.es/</a></td>
                <td><a href="mailto:contactanos@creditoflip.es">contactanos@creditoflip.es</a></td>
                <td>
                  <a href="https://creditoflip.es/privacy_policy.php" rel="nofollow noopener" target="_blank">Enlace</a>
                </td>
              </tr>
              <tr>
                <td rowspan="2">SIA LeadProm Media</td>
                <td>
                  <a href="https://www.creditron.org/es" rel="nofollow noopener" target="_blank"
                    >https://www.creditron.org/es</a
                  >
                </td>
                <td>
                  <a href="https://creditron.org/es/feedback" rel="nofollow noopener" target="_blank"
                    >formulario de contacto</a
                  ><br />+34 902 042 151
                </td>
                <td><a href="https://www.creditron.org/es/privacy" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td><a href="https://cashbro.net/es" rel="nofollow noopener" target="_blank">https://cashbro.net/es</a></td>
                <td>
                  <a href="https://cashbro.net/es/feedback" rel="nofollow noopener" target="_blank">formulario de contacto</a
                  ><br />+34 902 042 151
                </td>
                <td><a href="https://cashbro.net/es/privacy" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>SF Prestamos, SL</td>
                <td><a href="https://www.luzo.es/" rel="nofollow noopener" target="_blank">https://www.luzo.es/</a></td>
                <td><a href="mailto:info@luzo.es">info@luzo.es</a><br />+34 91 060 3553</td>
                <td><a href="https://www.luzo.es/documents/privacy" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>Trive Credit Spain, SL</td>
                <td><a href="https://mykredit.es/" rel="nofollow noopener" target="_blank">https://mykredit.es/</a></td>
                <td><a href="mailto:info@mykredit.es">info@mykredit.es</a><br />+34 912 907 100</td>
                <td><a href="https://mykredit.es/politica-privacidad" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>UAB GLOBAL AD LINKS</td>
                <td><a href="https://finandos.com/" rel="nofollow noopener" target="_blank">https://finandos.com/</a></td>
                <td>
                  <a href="https://finandos.com/en/contact-us" rel="nofollow noopener" target="_blank"
                    >formulario de contacto</a
                  ><br />+34 930 302 184
                </td>
                <td><a href="https://finandos.com/en/privacy-policy" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td rowspan="2">Wandoo Finance, SLU</td>
                <td><a href="https://wandoo.es/" rel="nofollow noopener" target="_blank">https://wandoo.es/</a></td>
                <td><a href="mailto:info@wandoo.es">info@wandoo.es</a><br />+34 917 14 66 20</td>
                <td><a href="https://wandoo.es/politica-de-privacidad" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td><a href="https://avinto.es/" rel="nofollow noopener" target="_blank">https://avinto.es/</a></td>
                <td><a href="mailto:info@avinto.es">info@avinto.es</a><br />+34 911 86 88 88</td>
                <td><a href="https://avinto.es/politica-de-privacidad" rel="nofollow noopener" target="_blank">Enlace</a></td>
              </tr>
              <tr>
                <td>4Finance Spain Financial Services, SAU</td>
                <td><a href="https://www.vivus.es/" rel="nofollow noopener" target="_blank">https://www.vivus.es/</a></td>
                <td><a href="mailto:info@vivus.es">info@vivus.es</a><br />+34 91 290 77 88/ +34 900 81 22 81</td>
                <td>
                  <a href="https://www.vivus.es/condiciones/politica-de-privacidad" rel="nofollow noopener" target="_blank"
                    >Enlace</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PartnersList',
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      countryCodeName: state => state.countryCodeName
    })
  },
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  max-width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }
  }
}
</style>
