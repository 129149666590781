export const ES_TRANSLATION = {
  main_header: '¿Qué producto estás buscando?',
  main_categories: ['Préstamos Rápidos', 'Reunificación de deudas', 'Tarjetas y cuentas', 'Con ASNEF'],
  prestamos_link: 'Préstamos',
  credit_score_link: 'Mi puntaje de crédito',
  header_1: '¡Obtén tu primer préstamo gratis!',
  header_2: 'Encontrar un préstamo es fácil con Opinomia',
  main_cta: 'Encontrar mi préstamo',
  stats_header: 'Elige la mejor oferta beneficiosa para ti',
  stats_1: ['50+', 'entidades financieras'],
  stats_2: ['5,000+', 'reseñas de clientes reales'],
  stats_3: ['50,000', 'usuarios por mes'],
  trust_header: '¿Por qué los usuarios confían en Opinomia?',
  trust_1_header: 'Información actualizada',
  trust_1_body: 'Nuestro equipo actualiza diariamente la información sobre los proveedores de préstamos. Cuando algún proveedor de préstamos cambia los términos o aparece una nueva oferta en el mercado, el equipo de Opinomia es el primero en conocerlo y actualizarlo.',
  trust_2_header: 'Ahorro de dinero',
  trust_2_body: 'Opinomia te ayuda a evitar ofertas de estafas y a encontrar rápidamente la mejor oferta del mercado. ¡Ahorramos tu tiempo y dinero! Te ofrecemos un puntaje de crédito gratuito. Y con nuestro asesoramiento personal también gratuito puedes mejorarlo y obtener las ofertas más beneficiosas para ti.',
  trust_3_header: 'Seguro',
  trust_3_body: 'Opinomia es un servicio que cumple con GDPR. La conexión de datos está protegida con SSL de accesos no autorizados y archivada en Google.',
  cta_header: 'Puntaje de crédito',
  cta_body: 'Descubre tu puntaje de crédito gratis y obtén recomendaciones personalizadas gratuitas sobre cómo mejorarlo.',
  cta_button: 'Descúbrelo gratis',
  cta_like: 'Tu puntaje de crédito es mejor que el 70% de los usuarios.',
  about_header: 'Sobre Opinomia',
  about_1_header: '¿Qué es Opinomia?',
  about_1_body: ['Opinomia es un servicio gratuito que te ayuda a tomar una decisión al elegir un producto financiero.', 'Recopilamos información sobre todas las ofertas disponibles en el mercado y la actualizamos cada vez que cambian sus términos. Las reseñas verificadas de clientes reales son para ayudar a los usuarios a evitar ofertas de estafa y elegir solo proveedores de préstamos seguros.', 'También ofrecemos puntaje de crédito gratuito y recomendaciones personalizadas gratuitas sobre cómo mejorarlo.'],
  about_2_header: '¿Cómo contribuir a nuestro servicio?',
  about_2_body: 'Si alguna vez solicitaste un préstamo, envía una revisión y comparte tu experiencia para ayudar a cientos de usuarios a elegir un proveedor de préstamos honesto y seguro.',
  about_button: 'Enviar una reseña',
  rights_disclamer: 'Todos los derechos reservados',
  terms_footer_link: 'Términos & Condiciones',
  privacy_footer_link: 'Política de privacidad',
  cookie_footer_link: 'Política de cookies',
  contact_footer_link: 'Contactos',
  unsub_footer_link: 'Darse de baja',
  partners_list_footer_link: 'Lista de socios',
  offers_reviews: '54 comentarios',
  offers_conditions: 'Сondiciones de préstamo',
  offers_amount_range: 'Importe',
  offers_min_interest: 'TAE',
  offers_timing_range: 'Plazo',
  offers_guarantee: 'Requiere garantía',
  offers_age_range: 'Edad',
  offers_get_loan: 'Obtener préstamo',
  offers_compare: 'Comparar',
  offers_currency: '€',
  offers_days: 'dias',
  offers_months: 'meses',
  offers_years: 'años',
  offers_percent: '%',
  offers_age: 'años',
  offers_delete: 'Eliminar',
  offers_difficulty: 'Facilidad de aplicación',
  offers_speed: 'Tiempo para recibir dinero',
  offers_hidden_commissions: 'Comisiones ocultas',
  offers_service: 'Atención al cliente',
  offers_satisfaction: 'Satisfacción de crédito',
  offers_interest_rate: 'Tasa de interés',
  offers_show: 'Ver detalles',
  offers_all_reviews: 'Todos los comentarios',
  offers_show_all_reviews: 'Mostrar todos los comentarios',
  offers_header: 'Préstamos online en España',
  offers_filter_average_rating: 'Clasificación',
  offers_filter_loan_sum: 'Importe',
  offers_filter_period: 'Plazo',
  offers_filter_first_loan_free: '0% de interés',
  offers_filters: 'Más',
  offers_free: 'primer préstamo gratis',
  offers_aggregator: 'Agregador de préstamos',
  offers_filter_guarantee: 'Requiere garantía',
  offers_filter_aggregator: 'Intermediarios',
  offers_filter_asnef: 'Acepta ASNEF',
  offers_filter_age: 'Edad',
  offers_save: 'Guardar',
  offers_clear: 'Quitar filtros',
  offers_rating_descr: 'Elija de qué calificación filtrar las ofertas de préstamos:',
  offers_show_results: 'Mostrar resultados',
  offers2_header: ', estas son tus ofertas personalizadas!',
  login_header: 'Iniciar sesión',
  logout_header: 'Cerrar sesión',
  login_register: 'Regístrate',
  login_subheader: 'Inicia sesión para evitar que recibamos revisiones falsas de organizaciones financieras.',
  login_email: 'Correo electrónico',
  login_name: 'Nombre',
  login_surname: 'Apellido',
  login_password: 'Contraseña',
  login_hide: 'Ocultar',
  login_show: 'Mostrar',
  login_continue: 'Continuar',
  login_or: 'o',
  login_fb: 'Continuar con Facebook',
  login_google: 'Continuar con Google',
  login_not_reg: '¿No te has registrado en Opinomia?',
  login_reg: 'Regístrate',
  login_terms: ['Doy mi consentimiento para el procesamiento de ', 'mis datos personales', ' y para recibir publicidad y otros mensajes informativos'],
  credit_header: 'Puntaje de crédito personal',
  credit_subheader: 'Descubre tu puntaje de crédito gratis y obtén recomendaciones personalizadas gratuitas sobre cómo mejorarlo.',
  credit_button: 'Descúbrelo gratis',
  credit_about_first: '¿Qué es el puntaje de crédito?',
  credit_about_first_body: 'El puntaje de crédito es una calificación que muestra cuáles son tus posibilidades de obtener un préstamo. Gracias a este puntaje, las entidades financieras determinan si pueden aprobar tu solicitud o no.',
  credit_about_second: '¿Cómo usarlo?',
  credit_about_second_body: 'Según tu puntaje de crédito, Opinomia te brinda un cálculo aproximado de las posibilidades de obtener un préstamo y qué entidades financieras son las mejores para ti. Opinomia también te ofrece recomendaciones personalizadas gratuitas sobre cómo mejorar tu puntaje de crédito.',
  credit_hiw_header: '¿Cómo funciona?',
  credit_hiw_1: 'Rellena una solicitud muy simple',
  credit_hiw_2: 'Nuestro algoritmo calcula tu puntaje de crédito y compara tus resultados con el promedio del mercado',
  credit_hiw_3: 'Recibes una lista de las entidades financieras que probablemente te otorguen un préstamo en función de tu puntaje de crédito',
  credit_hiw_4: 'Hacemos recomendaciones sobre cómo puedes mejorar tu puntaje',
  credit_cta_header: '¡Descubre cómo tu puntaje se compara con el promedio del mercado en solo unos minutos!',
  credit_cta_body: 'Los proveedores de préstamos tienen diferentes requisitos para los prestatarios. Te mostraremos las empresas que probablemente aprobarán tu solicitud de crédito.',
  credit_cta_button: 'Descúbrelo gratis',
  credit_security: 'Seguridad',
  credit_security_body: 'La conexión de datos está protegida con SSL de accesos no autorizados y archivada en Google.',
  credit_privacy: 'Privacidad',
  credit_privacy_body: 'Opinomia es un servicio que cumple con GDPR. Puedes eliminar tus datos de nuestro sistema cuando lo desees.',
  yes: 'si',
  no: 'no',
  clear: 'Borrar',
  review_header: 'Escribir reseña',
  review_company: 'Nombre de la compañía',
  review_q1: 'Préstamo obteniendo complejidad',
  review_q2: 'Tasa de interés',
  review_q3: 'Velocidad de recibir dinero',
  review_q4: 'Atención al cliente',
  review_q5: 'Satisfacción general del crédito',
  review_q6: 'Comisiones oculta',
  review_graduate: ['Muy malo', 'Malo', 'Normal', 'Bueno', 'Muy bueno'],
  review_interest_rate: ['Muy alta', 'Alta', 'Normal', 'Baja', 'Muy baja'],
  review_text: 'Tu opinión',
  review_submit: 'Enviar',
  account_change: 'Cambiar',
  account_save: 'Guardar',
  compare_header: 'Comparativa de tarjetas',
  success: 'Éxito',
  error: 'Error',
  account_header: 'Bienvenido de nuevo, ',
  account_credit_score: 'Puntaje de crédito',
  account_write_review: 'Enviar una reseña',
  account: 'Cuenta',
  description: 'Descripción',
  condition: 'Condiciones de préstamo',
  scam_offers: 'Ofertas de estafa',
  asnef: 'Acepta ASNEF',
  credit_score_header: 'Mi crédito',
  credit_score_subheader: '¡Descubra su puntaje de crédito gratis y obtenga recomendaciones personalizadas gratuitas sobre cómo mejorarlo!',
  credit_score_cta: 'Descubrir',
  credit_score_header_2: '¡Gracias',
  credit_score_subheader_2: ['Tu puntaje es de', 'y es mejor que el', '% de nuestros usuarios. *'],
  credit_score_subheader_3: 'Las siguientes entidades financieras otorgan préstamos a clientes con puntajes parecidos al tuyo:',
  credit_score_show_more: 'Ver todas las ofertas',
  credit_score_hint: '* el scoring es compuesto por empresa Fininity OÜ y sirve solo por razones informativos.',
  scam_header: ['Conoсe las ofertas de estafa', 'Protégete del fraude'],
  scam_subheader: 'El objetivo de Opinomia es hacer que el proceso de selección de un préstamo sea lo más seguro posible. En así que queremos advertir a nuestros usuarios sobre las ofertas de estafa y el potencial de fraude asociado con los préstamos.',
  scam_about_header: 'Pedir dinero por adelantado',
  scam_about_subheader: ['Es la estafa más común cuando se buscan préstamos online.', 'Sus víctimas más frecuentes son personas con perfiles crediticios complicados que han tenido dificultades para encontrar financiación y que acuden a empresas desconocidas.', 'Una empresa fiable sabe que pides dinero porque no lo tienes, así que evidentemente facilitará las condiciones para que lo obtengas rápido, pero NUNCA te cobrará NADA antes de darte el préstamo.'],
  scam_problem_header: 'Haznos saber tu experiencia con ofertas de estafa',
  scam_company_name: 'Nombre de la empresa fraudulenta',
  scam_problem_type: ['Tuve que pagar por la solicitud', 'Tuve que llamar al número de teléfono pagado', 'Otro'],
  scam_problem_placeholder: 'Tipo de problema',
  scam_problem: 'Describe tu problema',
  scam_button: 'Enviar',
  registration_form_header: 'Formulario de registro',
  registration_form_button: 'Enviar',
  privacy_checkbox: 'Confirmo que he leído y acepto las <a href="/terms" target="_blank">condiciones generales</a>, <a href="/privacy" target="_blank">política de privacidad</a> y <a href="/cookies" target="_blank">política de cookies</a>.',
  marketing_checkbox: 'Consiento estar al día de las últimas noticias y recibir ofertas especiales y descuentos por cualquier medio, incluidas las comunicaciones electrónicas o equivalentes de Fininity Ltd.',
  offers_period_dropdown: {
    '30 dias': 30,
    '6 meses': 180,
    '1 años': 365,
    '2 años': 730,
    '3 años': 1095,
    '4 años': 1460,
    '5 años': 1825
  },
  offers_period_dropdown_placeholder: 'Plazo de devolución',
  errors_translate: {
    'Server got itself in trouble': 'Hay algo que no anda bien, intenta realizar ese último paso más tarde',
    'User exist': 'El usuario ya está registrado',
    'Permission denied': 'El nombre de usuario y la contraseña no coinciden',
    'email: Not a valid email address.': 'Correo electrónico no valido',
    'password: Length must be between 1 and 255.': 'La contraseña debe tener entre 1 y 255 caracteres'
  },
  register_success: '¡Te has registrado con éxito!',
  debt_header: 'Reunificación de deudas',
  debt_subheader: 'La <b>reunificación de deudas</b> significa agrupar todos los préstamos e hipotecas en un solo préstamo. De esta manera, pagarás única cuota mensual cuya cuantía será menor a la suma de las diferentes cuotas a las que anteriormente tenías que pagar.',
  debt_questions: [
    ['¿En qué consiste la reunificación de deudas?', 'La <b>reunificación de deudas</b> significa agrupar todos los préstamos e hipotecas en un solo préstamo. De esta manera, pagarás única cuota mensual cuya cuantía será menor a la suma de las diferentes cuotas a las que anteriormente tenías que pagar.'],
    ['¿Cuáles son los requisitos para reunificar deudas?', 'Para ingresar al programa y negociar tu deuda normalmente necesitas deber a bancos, cajas de ahorro, supermercados, tiendas de electrodomésticos y microcréditos y estar atrasado en tus pagos.'],
    ['¿Para quién es la reunificación de deudas?', 'Para todas las personas a quienes las deudas han convertido en una bola de nieve.'],
    ['¿Cómo normalmente funciona?', 'La empresa analiza tu caso y te presenta un plan personalizado en el que especifica el monto aproximado con el que podrás liquidar tus deudas y el tiempo que te tomará hacerlo.']
  ],
  cards_header: 'Tarjetas de Crédito y Débito',
  cards_subheader: 'Hacer compras, sacar dinero sin comisiones, financiar viajes… Encuentra tu tarjeta perfecta para cada ocasión. En Opinomia publicamos solo las mejores tarjetas bancarias. ¡Compara gratis!',
  cards_questions: [
    ['¿Qué tipos de tarjetas hay?', 'Las más comunes son las <b>tarjetas de débito</b> . Te permiten acceder al saldo que tienes disponible en tu cuenta bancaria. Son ideales para hacer compras  y sacar efectivo en cajeros automáticos. <p>Las tarjetas que pueden funcionar como un préstamo son las <b>tarjetas de crédito</b>. Estas tarjetas tienen un límite de saldo a crédito disponible. Hay diferentes métodos de pago:</p> <ul style="list-style-type: circle;list-style-position: inside;"><li>pago aplazado</li><li>pago a fin de mes</li><li>otros;</li></ul>No necesariamente están asociadas a tu cuenta corriente. Las tarjetas que funcionan con un saldo limitado son las <b>tarjetas prepago</b>. Tú decides cuánto dinero ingresar en la tarjeta y ese será el saldo del que dispondremos. Son ideales, por ejemplo, para compras en Internet.'],
    ['¿Cuánto cuesta pedir una tarjeta bancaria nueva?', 'Lo primero que hay que pagar al pedir la tarjeta es la <b>cuota de emisión</b>. Según el banco y la tarjeta, el coste puede variar entre el 0 y los 80 euros. Otro pago importante es la <b>cuota de mantenimiento</b>, que generalmente es anual. El coste puede variar entre el 0 y los 150 euros al año. <b>Por retiradas de efectivo en cajeros</b> a veces también hay que pagar una comisión. Normalmente depende de si el cajero es de la misma entidad o de otro banco.']
  ],
  fcm_request: 'Permita las notificaciones para consultar el estado de su solicitud',
  landing_loans_purpose: [
    'Comprar coche',
    'Comprar moto',
    'Hacer un viaje',
    'Hacer reformas',
    'Agrupar deudas',
    'Otro'
  ],
  agreements_html: 'Confirmo que he leído y acepto las <a href="/terms" class="">condiciones generales</a>, <a href="/privacy" class="">política de privacidad</a> y <a href="/cookies" class="">política de cookies</a>.',
  agreements_error: 'Por favor, acepta los términos',
  reg_header: 'Introduce tu información de contacto',
  country_popup_header: '¡Sonríe! Estás en Opinomia 😊',
  country_popup_subheader: ['Esta es la web de Opinomia ', '. Selecciona tu país y verás tooooodo lo que hemos hecho para ti.'],
  cookiesText: 'Si continúas navegando, consideramos que aceptas política de cookies.',
  cookiesLink: 'politica-de-cookie',
  cookiesAcceptText: 'Aceptar',
  cookiesMoreText: 'Leer más'
}
