<template>
  <div>
    <div v-if="countryCodeName === 'es'" class="footer-text"><p>El plazo de los préstamos que comparamos oscila entre 3 meses y 4 años y la TAE entre un 4,00% y un 30%. Las condiciones varían en función de cada perfil. Ejemplo representativo: una financiación 10.000€ a 5 años y una TAE de un 6,11%, exigirá un importe a devolver de 11.630,40€.</p></div>
      <!-- footer-block -->
    <footer id="opi-footer">
      <div class="footer-left">
        <p class="footer-left__copyright">© <i class="year">{{(new Date()).getFullYear()}}</i> Opinomia.com. {{translation.rights_disclamer}}</p>
        <div class="footer-left__links">
          <router-link to="/terms">{{translation.terms_footer_link}}</router-link>
          <router-link to="/privacy">{{translation.privacy_footer_link}}</router-link>
          <router-link to="/cookies">{{translation.cookie_footer_link}}</router-link>
          <a v-if="translation.unsub_footer_link" :href="`https://unsub.opinomia.com/gdpr?country=${countryCodeName.toUpperCase()}`">
            {{translation.unsub_footer_link}}
          </a>
          <router-link v-if="translation.partners_list_footer_link" to="/partners-list">
            {{translation.partners_list_footer_link}}
          </router-link>
          <router-link to="/contacts">{{translation.contact_footer_link}}</router-link>
        </div>
      </div>
      <div class="footer-social">
        <a href="https://www.facebook.com/Opinomia/">
          <Isvg name="facebook"/>
        </a>
        <a href="">
          <Isvg name="instagram"/>
        </a>
      </div>
    </footer>
  <!-- footer-block ends -->
  </div>
</template>

<script>
import Isvg from '../assetComponents/Isvg'
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  components: {
    Isvg
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      countryCodeName: state => state.countryCodeName
    })
  }
}
</script>

<style scoped>
.footer-text {
  border-top: 1px solid #dadada;
  text-align: left;
  margin-top: 10px;
  padding: 10px 30px;
}
.footer-text p {
  color: #777;
  font-size: 11px;
}
</style>
